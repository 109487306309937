import React from 'react';
import Tooltip from 'rc-tooltip';

export default function Medium({ small }) {
  const tooltip = (
    <div className="indications">
      <p>Empfohlen für:</p>
      <ul>
        <li>Tennisellbogen</li>
        <li>Schlafstörungen</li>
        <li>Wechseljahresbeschwerden</li>
        <li>Migräne</li>
        <li>Achillodynie</li>
        <li>Beschwerden während der Schwangerschaft</li>
      </ul>
    </div>
  );
  
  const star = small ? '' : <span>*</span>;
  
  return (
    <div className="package medium">
      <div className="summary">
        <h3>Mediumpaket{star} mit 15% Rabatt</h3>
        <p><strong>10 Behandlungen</strong> à 60min/130 CHF (inkl. 1. Sitzung à 75min)</p>
        <p>Statt 1300 CHF für nur 1105 CHF</p>
        <p><strong>Sie sparen 195 CHF</strong></p>
        {small ? null : <p>(einzulösen innert 4 Monaten)</p>}
        {small ? null : <p><Tooltip overlay={tooltip}><span><i className="fa fa-info-circle" /></span></Tooltip></p>}
      </div>
    </div>
  );
}
